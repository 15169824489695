<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import moment from 'moment'

export default {
  name: 'institutions',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList.call(this, {
        url: '/dept/tree',
        isPage: false
      })
    },
    getHeader() {
      return [
        {
          name: '企业名称',
          type: 'input',
          key: 'firmName'
        },
        {
          name: '电话',
          type: 'input',
          key: 'sj'
        },
        {
          name: '统一社会信用代码',
          type: 'input',
          key: 'xydm'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'name',
          type: 'lt-120',
          title: '机构名称',
          filter: true,
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'leader',
          title: '机构负责人',
          type: 'lt-100',
          sorter: (a, b) => a.leader.length - b.leader.length
        },
        {
          dataIndex: 'id',
          title: '机构编码',
          type: 'lt-100',
          isShow: true,
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'sort',
          title: '排序号',
          type: 'lt-100',
          sorter: (a, b) => a.sort - b.sort
        },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'enable',
          title: '状态',
          width: '60px',
          type: 'badge',
          onExport: records => {
            return records ? '启用' : '禁用'
          },
          filters: [
            {
              text: '正常',
              value: 'true'
            },
            {
              text: '失效',
              value: 'false'
            }
          ],
          onFilter: (value, record) => record.enable == value,
          render(data) {
            return {
              showDot: true,
              name: data ? '正常' : '失效',
              color: data ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: records.enable ? '失效' : '正常',
                onClick: () => {
                  getAction(`/api/dept/${records.enable ? 'disabled' : 'enable'}/${records.id}`).then(result => {
                    this.getDataInit()
                  })
                }
              },
              {
                name: '添加子菜单',
                onClick: () => {
                  this.$router.push('/infoMain/institutionsDetail?isAddData=2&parentCode=' + records.id)
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push('/infoMain/institutionsDetail?isAddData=3&id=' + records.id)
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del
                    .call(this, {
                      url: `/dept/${records.id}`,
                      isPost: false
                    })
                    .then(() => {
                      this.getDataInit()
                    })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push('/infoMain/institutionsDetail?isAddData=1')
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        // headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        showPagination={false}
        filterRightButtonKey={['setting']}
      />
    )
  }
}
</script>

<style scoped lang="less">
tr {
  td {
    &:nth-of-type(1) {
      /deep/&.ant-table-column-has-sorters {
        display: flex;
        align-items: center;
        height: 57px;
      }
    }
  }
}
</style>
